<!-- =========================================================================================
  File Name: .vue
  Description: eCommerce Item Detail page
========================================================================================== -->

<template>
  <div class="document-center-container">
    <div class="vx-row">
      <div class="vx-col lg:w-1/1 w-full">
        <vx-card>
          <div>
            <p>
              <b></b>
            </p>
          </div>
        </vx-card>
      </div>
    </div>
    <br />
    <br />
    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base document-item"
        v-for="item in documentCenterData"
        :key="item.id"
      >
        <vx-card class="text-center cursor-pointer">
          <img :src="item.image" height="120" class="mx-auto mb-4" />
          <h5 class="mb-2 document-content">
              <a :href="item.url" class="document-link" target="_blank">{{ item.title}}</a>
            <feather-icon class="mb-2" icon="ChevronRightIcon" svgClasses="w-4 h-4"/>
          </h5>
          <p class="mb-2">{{ item.description }}</p>
          <!--small v-for="ptype in item.patientType" :key="ptype.value">{{ ptype }}</small -->
        </vx-card>
        </div>
    </div>


  </div>
</template>
<script>

import Prism from "vue-prism-component";

export default {
  components: {
    Prism
  },
  data() {
    return {
      treatmentSearchQuery: "",
      //Broken Tooth,Emergency, Extraction,Implant Consultation, Teeth Dentistry
      bannerImg:
        "https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png",
      error_occured: false,
      error_msg: "",
      selected: null,
      privacy: false,
      patientTypeOptions: [
        { label: "Existing", value: "1" },
        { label: "New", value: "0" }
      ],
      endDate: "",
      dayNo: 147,
      packId: 5,
      renderedAvalibilityAMPMInfo: [],
      selectedDayNo: "",
      slotStartHour: "",
      slotStartMinute: "",
      packAvailability: [],
      paris: "",
      baghdad: ""
    };
  },
  computed: {
    documentCenterData() {
      console.log(this.$store.state.careplan.documentCenterItems);

      return this.$store.state.careplan.documentCenterItems;
    },
    filteredKB() {
      //return this.$store.state.eCommerce.wishList.slice().reverse()
      //return this.appointmentTypeOptions.filter((item) => item.label.toLowerCase().includes(this.treatmentSearchQuery.toLowerCase()));
      return this.$store.state.careplan.appointmentTypeOptions.filter(item =>
        item.label
          .toLowerCase()
          .includes(this.treatmentSearchQuery.toLowerCase())
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.document-center-container {
  max-width: 1400px;
  margin: 0 auto;
  .document-item {
    .document-content {
      display: flex;
      justify-content: center;
      .feather-icon {
        margin: 0 !important;
        svg{
          color: $lightGreen;
        }
      }
    }
    p {
      padding: 10px 20px;
    }
  }
}
.knowledge-base-jumbotron-content {
  background-image: url("https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png");
  background-size: cover;
}

.document-link, .document-link:focus {
  color: var(--lGreen);
  font-size: 20px;
}
</style>
